import React from "react";
import { ThemeProvider } from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo";
import Navigation from "sections/Navigation";
import BannerTwo from "sections/BannerTwo";
import Service from "sections/Service";
import CoinFund from "sections/CoinFund";
import About from "sections/About";
import Awards from "sections/Awards";
import UserMap from "sections/UserMap";
import Wallet from "sections/Wallet";
import Statistics from "sections/Statistics";
import Stack from "sections/Stack"; 
import Faq from "sections/Faq";
import Footer from "sections/Footer";

import theme from "assets/theme/theme";
import GlobalStyle from "assets/theme";

import ReactGA from 'react-ga';

const TRACKING_ID = "G-5L3PPX35LR"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
// ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.pageview('/');
ReactGA.pageview('/blog-single');

const IndexPage = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <SEO title="Home" />
      <Navigation />
      <BannerTwo />
      <Service />
      {/* <CoinFund />
      <About />
      <Awards />
      <UserMap />
      <Wallet />
      <Statistics />
      <Stack /> 
      <Faq />
      <Footer /> */}
    </Layout>
  </ThemeProvider>
)

export default IndexPage
