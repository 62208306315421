import serviceIcon1 from "assets/images/service/service-icon-1.svg";
import serviceIcon2 from "assets/images/service/service-icon-2.svg";
import serviceIcon3 from "assets/images/service/service-icon-3.svg";
import serviceIcon4 from "assets/images/service/service-icon-4.svg";

export default {
  services: [
    {
      icon: serviceIcon1,
      title: "Stable Value",
      body: "Africoin is pegged to the US Dollar. 1 USD always equals 1 AFC (Africoin)",
    },
    {
      icon: serviceIcon2,
      title: "Instant & Cheap",
      body: "Start transacting today, instant transfers, insignificant transaction costs",
    },
    {
      icon: serviceIcon3,
      title: "Secure",
      body: "Secured by the EVM (Ethereum Virtual Machine). Major transaction network for decades to come",
    },
    {
      icon: serviceIcon4,
      title: "DAODC",
      body: "Africoin Digital Currency (DC) is governed by a DAO (Decentralized Autonomous Organization)",
    },
  ],
};
